import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function View({ styles, elements, ...props }) {
  const Elements = useComponents()
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0
  const background_color = styles.forElement("background").backgroundColor
  const checkboxes_color = styles.forElement("content_text").color
  const footer_bg_color = styles.forElement("copyright").backgroundColor
  const border_radius = styles.forElement("field").borderRadius

  return (
    <Elements.LandingPage {...props} style={styles.forElement("page")}>
      <div className="formkit-main">
        <div
          className="formkit-background"
          style={{ backgroundColor: background_color }}
        />
        <div
          className="formkit-background"
          style={styles.forElement("background")}
        />
        <div className="formkit-container">
          <Elements.Image
            className="formkit-image"
            name="image"
            size={{ h: 495, w: 292 }}
          />
          <div>
            <header>
              <Elements.Heading
                className="formkit-heading formkit-heading-primary"
                name="header"
                defaults={{
                  content: "Catchy headline goes here."
                }}
              />
            </header>
            <Elements.Region name="content" className="formkit-content">
              <Elements.Content
                defaults={{
                  content:
                    "Provide some more detail in this content area. Vestibulum ut ornare lobortis fermentum a consectetur etiam adipiscing litora consectetur dis lorem parturient himenaeos at ridiculus duis at a ad ridiculus parturient ipsum enim lorem scelerisque."
                }}
              />
            </Elements.Region>

            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields
                data-stacked={stackedDisplay}
                style={{
                  color: checkboxes_color,
                  borderRadius: border_radius
                }}
              >
                {stackedDisplay && <Elements.AddFieldButton />}
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Get the app" }}
                />
              </Elements.CustomFields>
              {!stackedDisplay && <Elements.AddFieldButton />}
            </Elements.Form>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </div>
        </div>
      </div>
      <footer
        className="formkit-footer"
        style={{ backgroundColor: footer_bg_color }}
      >
        <div className="formkit-footer-container">
          <Elements.Content
            name="copyright"
            defaults={{
              content: `All rights reserved`
            }}
          />
          <Elements.BuiltWith background="copyright" />
        </div>
      </footer>
    </Elements.LandingPage>
  )
}

View.style = "landing_page"
View.categories = ["Podcast", "Product", "Waitlist"]
View.thumbnail = ""
View.preview = "https://demo.ck.page/view"
View.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(View, { name: "View" })
